
import { Component, Vue, Prop, Watch, } from 'vue-property-decorator'
import { mapGetters, } from 'vuex'

@Component({
  components: {},
  computed: {
    ...mapGetters('ActivitiesModule', { selectedActivity: 'getSelectedActivity', }),
    ...mapGetters('SteppersModule', { finishedInfo: 'getFinishedInfo', }),
  },
})
export default class UpdateModal extends Vue {
  @Prop({ required: true, }) readonly showDialog!: boolean

  private dialog = false
  public selectedActivity: any
  public finishedInfo: any

  private goToMaintenance () {
    this.dialog = false
    this.$emit('close')
    this.$mixpanel.track('Ir a documentos', {
      Actividad: this.$store.state.ActivitiesModule.ActivitiesState.SelectedActivity.name,
    })
    this.$router.push({
      name: 'maintenanceDetail',
      params: { activity_id: this.$route.params.task_id, },
    })
  }

  private goToHistory () {
    this.$router.push({
      name: 'startupHistory',
    })
    this.$emit('close')
  }

  private closeModal () {
    this.dialog = false
    this.$emit('close')
  }

  @Watch('showDialog')
  show (val: boolean): void {
    if (val) {
      this.dialog = true
    } else {
      this.dialog = false
    }
  }

  @Watch('dialog')
  dialogClose (): void {
    if (this.dialog === false) {
      this.$emit('close')
    }
  }
}
