
import { Component, Vue } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import type { ConfigCompanyEnable } from '@/utils/ConfigCompaniesEnabled'
import Avatar from 'vue-avatar'
import { Frameworks } from '@/store/modules/auth/AuthModule'

type SectionKey = 'evidence' | 'compliance' | 'monitoring' | 'soc' | 'ethicalHacking'
@Component({
  components: {
    Avatar,
  },
  computed: {
    ...mapGetters('BusinessModule', ['getBusinessFrameworks']),
    ...mapGetters('AuthModule', ['getCompanyIdSelected', 'getCompanyFrameworksSelected']),
    ...mapGetters('PolimorficModule', ['getFramework', 'getFrameworkSelected']),
  },
})
export default class SideNavItemComponent extends Vue {
  constructor() {
    super()
    this.collapsedSections = {
      evidence: false,
      compliance: true,
      monitoring: false,
      soc: true,
      ethicalHacking: true,
    }
  }
  // private id = this.$store.getters["ActivitiesModule/getCompanyIdSelected"];
  public getCompanyIdSelected!: string
  public getCompanyFrameworksSelected!: Frameworks[]

  private Business = this.$store.state.BusinessModule.BusinessState.businessSelected
  openLinkPentesting() {
    window.open(this.url_pentesting_redirect, '_self', 'noopener,noreferrer')
  }

  openLink() {
    window.open(this.url_incidents_redirect, '_self', 'noopener,noreferrer')
  }
  private frameworks: any = this.Business.company
    ? this.Business.company.frameworks.sort((a: any, b: any) => {
      return a.name > b.name ? 1 : -1
    })
    : []
  private role = this.$store.state.AuthModule.AuthState.role
  /** Add to vault VUE_APP_URL_PENTESTING_REDIRECT**/
  private url_pentesting_redirect = process.env.VUE_APP_URL_PENTESTING_REDIRECT
  private url_spm_redirect = process.env.VUE_APP_URL_SPM_REDIRECT
  private url_incidents_redirect = process.env.VUE_APP_URL_INCIDENTS_REDIRECT
  private url_device_redirect = process.env.VUE_APP_URL_DEVICE_REDIRECT
  private url_domain_redirect = process.env.VUE_APP_URL_DOMAIN_REDIRECT
  private url_access_redirect = process.env.VUE_APP_URL_ACCESS_REDIRECT
  private url_assets_redirect = process.env.VUE_APP_URL_ASSETS_REDIRECT
  private url_trust_report_redirect = process.env.VUE_APP_URL_TRUST_REPORT_REDIRECT
  private url_staff_redirect = process.env.VUE_APP_URL_STAFF_REDIRECT
  private url_trainings_redirect = process.env.VUE_APP_URL_TRAININGS_REDIRECT
  private url_risks_redirect = process.env.VUE_APP_URL_RISKS_REDIRECT
  private url_command_center_redirect = process.env.VUE_APP_URL_COMMAND_CENTER
  private url_tasks_redirect = process.env.VUE_APP_URL_TASKS
  private env = process.env.VUE_APP_ENV
  private ADMIN_ROLES = process.env.VUE_APP_PRIVILEGED_ROLES?.split(',') || []

  private checkHistoryRouteNames(value: string) {
    const historyRoutesNames = [
      'startupHistory',
      'startupDashboardGap',
      'historyStartupControlsList',
      'historyStartupDetailControl',
    ]

    return historyRoutesNames.includes(value)
  }

  private roleFramework() {
    return this.isRole('Implementador') || this.isRole('Customer Success')
      ? this.getCompanyFrameworksSelected
      : this.frameworks
  }
  private goToSPM() {
    window.location.replace(process.env.VUE_APP_URL_SPM_REDIRECT || '')
  }

  private getNameCompany() {
    return this.$store.getters['AuthModule/getCompanyNameSelected']
  }
  private redirectPath() {
    this.$router.push('/app/clients')
  }
  private isRole(role: string) {
    return this.$store.state.AuthModule.AuthState.role.includes(role)
  }
  enableMenuIncidents(): boolean | undefined {
    const enableMenu = localStorage.getItem('enabledMenuIncidents')
    if (enableMenu === 'true') return true
    return false
  }

  get vuex() {
    return JSON.parse(localStorage.getItem("vuex") || '{}');
  }

  get rolesVuex(): string[] {
    return this.vuex.AuthModule.AuthState.role;
  }

  isModuleAbledByRole(module: string): boolean {
    return this.rolesVuex.some((role: string) => role.includes(module));
  }

  validateAccessBeta(moduleName: string): boolean | undefined {
    const companyId: string = this.$store.state.AuthModule.AuthState.company_id.toString().trim()
    const companies = localStorage.getItem('enabledCompanies')
    if (companies === null) return false
    const config: ConfigCompanyEnable[] = JSON.parse(companies)
    const module = config.find((m) => m.module === moduleName)

    if (module === null) return false
    if (module?.isEnabledAll) return true
    else return module?.companiesID.includes(companyId)
  }

  goToRisk() {
    this.$mixpanel.track('Ingresó a Riesgos')
  }
  hideSidaBarImplementation() {
    this.collapsedSections.compliance = true
    const userData = this.role;
    if (!this.ADMIN_ROLES.some((adminRole: any) => userData.includes(adminRole))) {
      window.location.replace(process.env.VUE_APP_STARTUP_ACTIVITY_URL || '/startup/activity')
    } else {
      window.location.replace(`/startup/activity?companyId=${this.getCompanyIdSelected}`)
    }
  }
  hideSidaBarDocument() {
    this.collapsedSections.compliance = true
    window.location.replace(`/startup/documents`)
  }
  goToAudits() {
    window.location.replace(process.env.VUE_APP_URL_AUDITS_REDIRECT || '')
    this.$mixpanel.track('Ingresó a Auditoría')
  }

  goToCompliance(framework: Frameworks) {
    const name = !this.ADMIN_ROLES.some(adminRole => this.role.includes(adminRole)) ? 'project' : 'category'
    this.$router.push({ name, params: { id: String(framework.id) } })
    this.$store.commit('PolimorficModule/SET_FRAMEWORK_SELECTED', framework)
  }

  mounted() {
    if (String(this.$route.path).search('project') !== -1) {
      this.collapsedSections.compliance = false
    } else {
      this.collapsedSections.compliance = true
    }
  }

  collapsedSections = {
    evidence: false,
    compliance: false,
    monitoring: true,
    soc: true,
    ethicalHacking: true,
  }

  toggleCollapse(section: SectionKey) {
    this.collapsedSections[section] = !this.collapsedSections[section]
  }
}
